import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignInPage from './Components/SigninpageComponent';
import HomePageComponent from './Components/HomePageComponent';
import Dashboard from './Components/DashboardComponent';
import CurrentPlansComponent from './Components/PlansComponents/CurrentPlansComponent';
import AddPlansComponent from './Components/PlansComponents/AddPlansComponent';
import InvestmentsComponent from './Components/InvestmentsComponent';
import MonthlyReturns from './Components/MonthlyreturnsComponent';
import AddBankcomponent from './Components/AddBankComponent';
import Withdrawn from './Components/WithdrawnComponent';
import AppUsers from './Components/UserlistComponent';
import FeedbackForm from './Components/FeedbackComponent';
import FrequentlyQuestions from './Components/AskedquestionsComponent';
import Howtouse from './Components/Howtouse';
import Knowmoreaboutlending from './Components/PlansComponents/Knowmoreaboutlending'
import ContactdetailsComponent from './Components/ContactdetailsComponent';
import Privacypolicy from './Components/PrivacypolicyComponent';
import Referralscreen from './Components/ReferralComponent';
import ProtectedRoute from './Components/ProtectedRoute';
import FloatContainer from './Components/FloatcontainerComponent';
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SignInPage />} />
      <Route path="/home" element={<HomePageComponent />}>
        <Route path="dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="plans/current" element={<ProtectedRoute><CurrentPlansComponent /></ProtectedRoute>} />
        <Route path="plans/add" element={<ProtectedRoute><AddPlansComponent /></ProtectedRoute>} />
        <Route path="Userlist" element={<ProtectedRoute><AppUsers /></ProtectedRoute>} />
        <Route path="investments" element={<ProtectedRoute><InvestmentsComponent /></ProtectedRoute>} />
        <Route path="monthlyreturns" element={<ProtectedRoute><MonthlyReturns /></ProtectedRoute>} />
        <Route path="AddBank" element={<ProtectedRoute><AddBankcomponent /></ProtectedRoute>} />
        <Route path="Howtouse" element={<ProtectedRoute><Howtouse /></ProtectedRoute>} />
        <Route path="withdrawn" element={<ProtectedRoute><Withdrawn /></ProtectedRoute>} />  
        <Route path="feedback" element={<ProtectedRoute><FeedbackForm /></ProtectedRoute>} />
        <Route path="frequentlyQuestions" element={<ProtectedRoute><FrequentlyQuestions /></ProtectedRoute>} />
        <Route path ='Knowmore' element={<ProtectedRoute><Knowmoreaboutlending/></ProtectedRoute>}/>
        <Route path='contactdetails' element={<ProtectedRoute><ContactdetailsComponent/></ProtectedRoute>}/>
        <Route path='privacypolicy' element={<ProtectedRoute><Privacypolicy/></ProtectedRoute>}/>
        <Route path='referralscreen' element={<ProtectedRoute><Referralscreen/></ProtectedRoute>}/>
        <Route path ='floatcontainer'element={<ProtectedRoute><FloatContainer/></ProtectedRoute>}/>
      </Route> 
    </Routes>
  );
};

export default App;
