import React, { useState, useEffect } from 'react';
import { 
  FaTachometerAlt, 
  FaClipboardList, 
  FaClipboardCheck, 
  FaPlus, 
  FaUsers, 
  FaMoneyBillWave, 
  FaMoneyCheckAlt, 
  FaChartLine, 
  FaBuilding, 
  FaUserCog, 
  FaComments, 
  FaQuestionCircle, 
  FaSignOutAlt, 
  FaBars, 
  FaPowerOff 
} from 'react-icons/fa';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import Logo from './Circle_logo.png';
import './HomePageComponent.css';

const HomePageComponent = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/home/dashboard');
    }
  }, [location.pathname, navigate]);

  const toggleSidebar = () => setIsSidebarOpen(prev => !prev);
  const handleMenuClick = (route) => {
    navigate(route);
    setActiveSubMenu(null); // Close any open submenus on menu item click
  };

  const isActive = (route) => location.pathname === route;

  const handleLogout = () => {
    localStorage.clear(); // Clear the login time
    navigate('/'); // Redirect to the login page
  };
  
  return (
    <div className="page-container">
      <div className={`sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-logo-container">
          <img className="sidebar-logo-icon" src={Logo} alt="AWG Circle" />
          {isSidebarOpen && (
            <div className="sidebar-company-info">
              <h4 className="sidebar-company-name">AWG</h4>
              <p className="sidebar-company-tagline">Finserv Pvt Ltd</p>
            </div>
          )}
        </div>

        <div className="sidebar-menu">
          <div className={`menu-item ${isActive('/home/dashboard') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/dashboard')}>
            {isSidebarOpen && <span>Dashboard</span>}
            <FaTachometerAlt className="sidebar-icon" />
          </div>

          <div className={`sidemenu-part ${activeSubMenu === 'Plans' ? 'active' : ''}`}>
            <div className="menu-item" onClick={() => setActiveSubMenu(activeSubMenu === 'Plans' ? null : 'Plans')}>
              {isSidebarOpen && <span>Plans</span>}
              <FaClipboardList className="sidebar-icon" />
            </div>
            <div className={`submenu ${activeSubMenu === 'Plans' ? 'submenu-open' : ''}`}>
              <span className={`submenu-item ${isActive('/home/plans/current') ? 'active' : ''}`} onClick={() => navigate('/home/plans/current')}>
                {isSidebarOpen && <span>Current Plans</span>}
                <FaClipboardCheck className="sidebar-icon" />
              </span>
              <span className={`submenu-item ${isActive('/home/plans/add') ? 'active' : ''}`} onClick={() => navigate('/home/plans/add')}>
                {isSidebarOpen && <span>Add Plans</span>}
                <FaPlus className="sidebar-icon" />
              </span>
            </div>
          </div>

          <div className={`menu-item ${isActive('/home/Userlist') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/Userlist')}>
            {isSidebarOpen && <span>Users List</span>}
            <FaUsers className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/investments') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/investments')}>
            {isSidebarOpen && <span>Investments</span>}
            <FaMoneyBillWave className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/Withdrawn') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/Withdrawn')}>
            {isSidebarOpen && <span>Withdrawals</span>}
            <FaMoneyCheckAlt className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/monthlyreturns') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/monthlyreturns')}>
            {isSidebarOpen && <span>Rate Of Interest</span>}
            <FaChartLine className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/AddBank') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/AddBank')}>
            {isSidebarOpen && <span>Add Bank Account</span>}
            <FaBuilding className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/privacypolicy') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/privacypolicy')}>
            {isSidebarOpen && <span>Privacy Policy</span>}
            <FaUserCog className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/Howtouse') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/Howtouse')}>
            {isSidebarOpen && <span>How to use</span>}
            <FaQuestionCircle className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/Knowmore') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/Knowmore')}>
            {isSidebarOpen && <span>Know More About Lending</span>}
            <FaQuestionCircle className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/contactdetails') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/contactdetails')}>
            {isSidebarOpen && <span>Contact Details</span>}
            <FaUserCog className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/feedback') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/feedback')}>
            {isSidebarOpen && <span>Feedback</span>}
            <FaComments className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/feedback') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/floatcontainer')}>
            {isSidebarOpen && <span>FloatContainer</span>}
            <FaComments className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/frequentlyQuestions') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/frequentlyQuestions')}>
            {isSidebarOpen && <span>Frequently Asked Questions</span>}
            <FaQuestionCircle className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/home/referralscreen') ? 'active' : ''}`} onClick={() => handleMenuClick('/home/referralscreen')}>
            {isSidebarOpen && <span>Referral</span>}
            <FaClipboardCheck className="sidebar-icon" />
          </div>

          <div className={`menu-item ${isActive('/') ? 'active' : ''}`} onClick={handleLogout}>
            {isSidebarOpen && <span>Logout</span>}
            <FaSignOutAlt className="sidebar-icon" />
          </div>
        </div>
      </div>

      <div className={`header-container ${isSidebarOpen ? 'header-expanded' : 'header-collapsed'}`}>
        <div className="header-content">
          <FaBars className="menu-icon" color='#ccc' size={20} onClick={toggleSidebar} />
          <div className="actions-container" onClick={handleLogout}>
            <FaPowerOff className="power-icon" />
          </div>
        </div>
      </div>

      <div className={`main-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Outlet /> 
      </div>
    </div>
  );
};

export default HomePageComponent;
